<template>
  <header>
      <!-- Fixed navbar -->
      <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-jmred">
        <div class="container-fluid justify-content-center">
        <!-- Logo centrado -->
        <a class="navbar-brand" href="#">
            <img src="./assets/logojm.png" alt="Logo" style="max-height: 50px;">
        </a>
    </div>
      </nav>
    </header><main role="main" class="container">
      <div class="container d-flex align-items-center" style="height: 100vh;">
  <div class="row justify-content-center w-100">
    <div class="col col-lg-4">
      <div class="card bg-white mx-auto text-center text-dark">
        <div class="card-body ">
            <p class="card-text">Buses JM, es viajar con agrado..</p>
        </div>
      </div>
    </div>
  </div>
</div>
  <video poster="./assets/BGJm-2.jpg" id="bgvid" playsinline autoplay muted loop>
          <!-- WCAG general accessibility recommendation is that media such as background video play through only once. Loop turned on for the purposes of illustration; if removed, the end of the video will fade in the same way created by pressing the "Pause" button  -->
          <source src="./assets/loopbgjm.mp4" type="video/mp4">
      </video>
    </main>

    <footer class="footer">
      <div class="container">
        <span><p class="text-muted text-center text-white">by clertic.</p></span>
      </div>
    </footer>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
